import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from "react";
import '../App.css';
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';
import buttonyellow from '../assets/images/button-yellow.svg';
import cuenta_atras from '../assets/images/bufandeo-cuenta-atras.svg';
import bufandeo from '../assets/images/bufandeo.gif';
import bufandeo_gira from '../assets/images/bufandeo-gira.svg';

export { Bufandeo };

function Bufandeo() {
  const navigate = useNavigate();
  const location = useLocation();
  const [counter, setCounter] = useState(global.bufandeodetails.bufandeo_time_seconds);
  const [error, setError] = useState(null);
  let limit = 3;
  let noDataSensors = "No se ha podido obtener datos del sensor"+"<br>"+"por favor vuelva a intentarlo";
  const enviar = () => {
    disableDeviceSensor();
    navigate('/juegos/Enviarbufandeo');
  };

  const  volver = () => {
    disableDeviceSensor();
    navigate('/juegos/Juegos');
  }
  
  if(global.media_fondo_app==undefined){global.media_fondo_app = background;}

  let DeviceMotionPermission = false;
  let DeviceOrientationPermission = false;
  let contdown = global.bufandeodetails.bufandeo_time_seconds;
  let totalRotationZ = 0;
  let lastAlpha = null;
  let lastTimestamp = null;
  let rotationsCount = 0;
  const rotationThreshold = 180;
  const angularVelocityThreshold = 50;
  const accelerationYThreshold = 30;
  const smoothingFactor = 0.2;
  let timeObject1 = new Date();
  let accelerationX = 0;
  let accelerationY = 0;
  let accelerationZ = 0;

  function timeDiff(mode){
    let milliseconds = 1000;
    let timeObject2 = new Date(timeObject1.getTime() + milliseconds);
    let timeObject3 = new Date();
    switch(mode){            
      case 'sameTime':  
        if(timeObject3 == timeObject1){return true;}else {return false;}  
      break;
      case 'counter':
      if(timeObject3 >= timeObject2){
        if(contdown == 1){setCounter(0);}
        else if(contdown > 1){
          contdown--;
          timeObject1 = timeObject3;
          let dataCounter = document.getElementById("counter-bufandeo");
          dataCounter.innerHTML = contdown;
          if(contdown == limit){dataCounter.classList.toggle('counter-bufandeo-limit');}
          dataCounter=null;
        }
      }
      break;
    }
  }

  function handleFullRotation() {
    //if (!timeDiff('sameTime')) {
      rotationsCount++;
      global.turnsbufandeo = rotationsCount;
    //}
    // Reinicia el seguimiento después de detectar una vuelta completa
    totalRotationZ = 0;
  }

  function handleMotion(event) {
    timeDiff('counter');
    accelerationX = parseFloat(event.rotationRate.alpha).toFixed(2);
    accelerationY = parseFloat(event.rotationRate.beta).toFixed(2);
    accelerationZ = parseFloat(event.rotationRate.gamma).toFixed(2);
  }

  function handleOrientation(event) {
    timeDiff('counter');
    var data = document.getElementById("gyroevents");
    if(event.alpha === null){data.innerHTML = noDataSensors;}
    let alpha = event.alpha || 0;

    // Convertir alpha a un rango de -180 a 180 grados para manejar rotación a la izquierda y a la derecha
    if (alpha > 180) {alpha -= 360;}

    if (lastAlpha !== null && lastTimestamp !== null) {
      const deltaTimestamp = event.timeStamp - lastTimestamp;
      
      // Calcula la velocidad angular en grados por segundo
      const angularVelocityZ = ((alpha - lastAlpha) / deltaTimestamp) * 1000;

      // Verifica si la velocidad angular supera el umbral
      if (Math.abs(angularVelocityZ) > angularVelocityThreshold && accelerationY > accelerationYThreshold) {
        // Aplica suavizado al cambio total en la orientación en el eje Z
        totalRotationZ = smoothingFactor * totalRotationZ + (1 - smoothingFactor) * (alpha - lastAlpha);
      }

      // Verifica si se ha completado una vuelta entera alrededor del eje Z
      if (Math.abs(totalRotationZ) >= rotationThreshold) {
        handleFullRotation();
      }

  }
  lastAlpha = alpha;
  lastTimestamp = event.timeStamp;  
  }

  function enableDeviceSensor() {
    // Request permission for iOS devices
    if(global.sensors === true){
      //DeviceMotionEvent
      if (typeof DeviceMotionEvent.requestPermission === 'function') {
          DeviceMotionEvent.requestPermission()
            .then(permissionState => {
              if (permissionState === 'granted') {
                  DeviceMotionPermission = true;
                  window.addEventListener("devicemotion", handleMotion);
              }
            })
            .catch(console.error);
      } else {
          // handle regular non iOS devices
          DeviceMotionPermission = true;
          window.addEventListener("devicemotion", handleMotion);
      }
      //DeviceOrientationEvent
      if (typeof DeviceOrientationEvent.requestPermission === 'function') {
          DeviceOrientationEvent.requestPermission()
            .then(permissionState => {
              if (permissionState === 'granted') {
                  DeviceOrientationPermission = true;
                  window.addEventListener("deviceorientation", handleOrientation);
              }
            })
            .catch(console.error);
        } else {
          // handle regular non iOS devices
          DeviceOrientationPermission = true;
          window.addEventListener("deviceorientation", handleOrientation);
        }
    }
  }

  function disableDeviceSensor(){
      window.removeEventListener("devicemotion", handleMotion);
      window.removeEventListener("deviceorientation", handleOrientation);
  }    

  /*Si se obtuvieron permiso de acceso a sensores y los parametros del juego activo*/
  if(global.sensors === true){
  if(global.gameactive === true && global.gametype == "bufandeo" && counter > 0){
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
        {!global.media_logo_app ? (<header className="App-header">
          <div><img src={logo} className="App-logo" alt="logo" /></div>
        </header>) : (<header className="App-header">
          <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
        </header>)}
        
        <div className="App-body">
          <div className="contenedor">
            <img src={cuenta_atras} className="countdown" alt="countdown" />
            <div id="counter-bufandeo" className="counter-bufandeo">{contdown}</div>
          </div>
          <img src={bufandeo} className="bufandeo-hand" />
          <img src={bufandeo_gira} className="bufandeo-turn"/>
          <div className="contenedor" onClick = {volver}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonblue" />
            <div className="centrado">Volver</div>
          </div>
          <div className='gyroevents' id="gyroevents"></div>

          {global.footer_in_game!=null && <img src={global.footer_in_game} className="App-banner-footer-in-game" alt="bannerfooter" />}
        </div>

        <footer className="App-footer">
          <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
        </footer>
        {enableDeviceSensor()}
      </div>
      );
  } else {
      enviar();
  } 
  } 
  
  /*Si no han permitido los permisos de cámara*/
  if(!global.sensors){
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
        <div id="games" className='game-container'>
          <p className="avisos">Para utilizar el juego, debe permitir el permiso de acceso a los sensores</p>
        </div>
        <div className="contenedor" onClick = {volver}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonblue" />
            <div className="centrado">Volver</div>
        </div>
      </div>

      <footer className="App-footer">
      <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div>
    );
  }

  /*Si no hay juegos activos*/
  if(global.gameactive != true){
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
        <div id="games" className='game-container'>
          <p>No hay juegos activos en este momento</p>
          <div className="contenedor" onClick = {volver}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonblue" />
            <div className="centrado">Volver</div>
          </div>
        </div>
      </div>

      <footer className="App-footer">
      <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div>
    );
  }

}
