import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '_store';

export { Nav };

function Nav() {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    // only show nav when logged in
    if (!auth) {return null;}
    
    return (
        
        <nav className="navbar navbar-expand navbar-dark bg-dark px-3">
            <div className="navbar-nav">
                <NavLink to="/" className="nav-item nav-link "> Home </NavLink>
                <NavLink to="/Bienvenida" className="nav-item nav-link"> Bienvenida </NavLink>
                <NavLink to="/Condicioneslegales" className="nav-item nav-link"> Condiciones Legales </NavLink>
                <NavLink to="/Cookie" className="nav-item nav-link"> Cookie </NavLink>
                <NavLink to="/Permisos" className="nav-item nav-link"> Permisos </NavLink>
                <NavLink to="/Register" className="nav-item nav-link"> Registro </NavLink>
                <button onClick={logout} className="btn btn-link nav-item nav-link">Logout</button>
            </div>
        </nav>
    );
}