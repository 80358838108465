import styled, { keyframes, css } from "styled-components";
var mask = global.gamebg;

const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
  width: 350px;
  /*max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
  max-height: ${({ maxHeight }) => maxHeight && `${maxHeight}px`};*/
  overflow: hidden;
  border: 4px solid #ffdd33;
  border-radius: 10px;
`;

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Preview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Video = styled.video`
  position: relative;
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }

  /*MirrorOFF*/
  /*
  -moz-transform:scale(1);
  -webkit-transform:scale(1);
  -o-transform:scale(1);
  -ms-transform:scale(1);
  transform:scale(1);*/

  /*MirrorON*/
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
`;

export const Overlay = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
  border: 1px solid red/*#ffffff*/;
  border-radius: 10px;
  align: center;
`;

export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0;

  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
  }}
`;

export const Button = styled.button`
  width: 75%;
  min-width: 100px;
  max-width: 250px;
  margin-top: 24px;
  padding: 12px 24px;
  background: silver;
`;

export const Mascara = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  /*border: solid 1px red;*/
`;