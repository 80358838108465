import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Juegos, Fotocam, Enviarfoto, Quiz, Enviarquiz, Bufandeo, Enviarbufandeo, Encuesta, Enviarencuesta } from './';

export { JuegosLayout };

function JuegosLayout() {
    const auth = useSelector(x => x.auth.value);

    return (
        /*<div className="container">
            <div className="row">
                <div className="col-sm-8 offset-sm-2 mt-5">*/
                    <Routes>
                        <Route path="juegos" element={<Juegos />} />
                        <Route path="fotocam" element={<Fotocam />} />
                        <Route path="enviarfoto" element={<Enviarfoto />} />
                        <Route path="quiz" element={<Quiz />} />
                        <Route path="enviarquiz" element={<Enviarquiz />} />
                        <Route path="bufandeo" element={<Bufandeo />} />
                        <Route path="enviarbufandeo" element={<Enviarbufandeo />} />
                        <Route path="encuesta" element={<Encuesta />} />
                        <Route path="enviarencuesta" element={<Enviarencuesta />} />
                    </Routes>
                /*</div>
            </div>
        </div>*/
    );
}