import { useEffect, useState } from "react";
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import '../App.css';
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';
import bufandeoon from '../assets/images/bufandeo-on.svg';
import bufandeooff from '../assets/images/bufandeo-off.svg';
import quizon from '../assets/images/quiz-on.svg';
import quizoff from '../assets/images/quiz-off.svg';
import fotocamon from '../assets/images/fotocam-on.svg';
import fotocamoff from '../assets/images/fotocam-off.svg';
import encuestaon from '../assets/images/encuesta-on.svg';
import encuestaoff from '../assets/images/encuesta-off.svg';
import buttonblue from '../assets/images/button-blue.svg';
import buttonyellow from '../assets/images/button-yellow.svg';

export { Juegos };

function Juegos() {

    const navigate = useNavigate();

    const  quiz = () => {
      if(global.gameactive == true && global.gametype == "quiz"){
        if(!global.quizparticipate){navigate('/juegos/Quiz');}
        else {alert("Solo puede participar una vez")}
      }
    }
    
    const  fotocam = () => {
      if(global.gametype=="fotocam"){navigate('/juegos/Fotocam');}
    }

    const  bufandeo = () => {
      if(global.gametype=="bufandeo"){navigate('/juegos/Bufandeo');}
    }
    const  encuesta = () => {
      if(global.gameactive == true && global.gametype == "encuesta"){
        if(!global.surveyparticipate){navigate('/juegos/Encuesta');}
        else {alert("Solo puede participar una vez")}
      }
    }

    const resetGamesVars = () => {
      global.fotobase64=null;
      global.fotocomentario=null;
      global.index=0;
      global.gamename = null;
      global.gameid = null;
      global.gameactive = null;
      global.gametype = null;
      global.gamebg = null;
      global.quizdetails = null;
      global.bufandeodetails = null;
      global.surveydetails = null;
    }
    
    const releaseGames = () => {
      if(global.quizparticipate){global.quizparticipate=false;global.previousgameid=null;}
      if(global.surveyparticipate){global.surveyparticipate=false;global.previousgameid=null;}
    }

    const reload = () => {
      resetGamesVars();
      setIsLoading(true);
    };

    const  salir = () => {
      resetGamesVars();
      global.eventslug=null;
      global.token=null;
      global.exit=true;
      navigate('/')
    }
    
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [gamename, setGameName] = useState(null);
    const [gameid, setGameId] = useState(null);
    const [gameactive, setGameActive] = useState(null);
    const [gametype, setGameType] = useState(null);
    const [gamebg, setGameBg] = useState(null);

    //Activar juegos
    if(global.gameactive==true && global.gametype=="fotocam"){var fotocamstatus = fotocamon;}
    else{var fotocamstatus = fotocamoff;}
    if(global.gameactive==true && global.gametype=="bufandeo"){var bufandeostatus = bufandeoon;}
    else{var bufandeostatus = bufandeooff;}
    if(global.gameactive==true && global.gametype=="quiz"){var quizstatus = quizon;}
    else{var quizstatus = quizoff;}
    if(global.gameactive==true && global.gametype=="encuesta"){var encuestastatus = encuestaon;}
    else{var encuestastatus = encuestaoff;}
    if(global.media_fondo_app==undefined){global.media_fondo_app = background;}

    useEffect(() => {
    if (isLoading) {
      async function fetchData() {
        try {
          const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+global.token}
          };
          const response = await fetch(process.env.REACT_APP_API_URL+process.env.REACT_APP_GAME, requestOptions).catch(error => {});
          if (response.ok) {
            resetGamesVars();
            const result = await response.json();
            //console.log(result);
            for(var k in result) {
              if(result[k].active == true){
                setGameName(result[k].name);
                setGameId(result[k].name);
                setGameActive(result[k].active);
                setGameType(result[k].type);                
                global.gamename = result[k].name;
                global.gameid = result[k].id;
                global.gameactive = result[k].active;
                global.gametype = result[k].type;
                if(result[k].bg!=null){
                  let tmp = result[k].bg.split('/');
                  let bg = process.env.REACT_APP_HTTP_HOST+"/media/"+tmp[4];
                  setGameBg(bg);
                  global.gamebg = bg;
                } else {setGameBg(result[k].bg); global.gamebg = result[k].bg;}
                
                switch (result[k].type){
                  case 'quiz':
                    global.quizdetails = result[k].details;
                    break;
                  case 'bufandeo':
                    global.bufandeodetails = result[k].details;
                    global.turnsbufandeo = 0;
                    break;
                  case 'encuesta':
                     global.surveydetails = result[k].details;
                    break;
                  default:
                    //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
                    break;
                }
                //releaseGames vars
                if((global.previousgameid != result[k].id && result[k].type == "quiz" && global.quizparticipate == true)
                  || (global.previousgameid != result[k].id && result[k].type == "encuesta" && global.surveyparticipate == true)
                  ||(result[k].active == true && global.previousgameid != result[k].id)) {
                  releaseGames();
                }  
              }
            }
            setError(null);
            setIsLoading(false);
          } else {
            setError("Hubo un error al obtener el objeto");
            setIsLoading(false);
          }
        } catch (error) {
          setError("Error al realizar la solicitud");
          setIsLoading(false);
        }
      }
      fetchData();
      } else {
        const timeout = setTimeout(() => {
          setIsLoading(true);
        }, 10000)
        return () => clearTimeout(timeout)
      }
    }, [isLoading]);

    //Loader
    if (isLoading) {
      return (
        <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
          <div className="App-loader-background"><div className="App-loader"></div></div>
        </div>
      );
    }

    //Sihubo un error al realizar la petición
    if (error) {
      return (
        <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
          <h1>{error}</h1>
          <div className="contenedor" onClick = {reload}>
          <img src={buttonblue} className="buttonblue" alt="buttonblue" />
          <div className="centrado">Reintentar</div>
        </div>
        <div className="contenedor" onClick = {salir}>
          <img src={buttonyellow} className="buttonyellow" alt="buttonblue" />
          <div className="centrado">Cerrar sesión</div>
        </div>
        </div>
      );
    }

    //Si la petición se realizó correctamente mostamos los juegos
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}
      <div className="App-body">
         {/* GAMES */}
        <div id="games" className='games-container'>
          <h4>Pulsa para acceder al juego activo </h4>
          <div id="quiz" className=''><img src={quizstatus} className="game" alt="" onClick={quiz}/></div>
          <div id="bufandeo" className=''><img src={bufandeostatus} className="game" alt="" onClick={bufandeo}/></div>
          <div id="fotocam" className=''><img src={fotocamstatus} className="game" alt="" onClick={fotocam}/></div>
          <div id="encuesta" className=''><img src={encuestastatus} className="game" alt="" onClick={encuesta}/></div>
        </div>
        {/* BANNER */}
        {global.footer_games!=null && <img src={global.footer_games} className="App-banner-footer" alt="bannerfooter" />}
      </div>

      <footer className="App-footer">
        <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div> 
    );
}