import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import "../App.css";

export { Registrar };

function Registrar() {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [token, setToken] = useState(null);
  const [event, setEvent] = useState(null);
  
  const navigate = useNavigate();
  const  next = () => {
    navigate('/bienvenidauser');
  }
  const reload = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    if (isLoading) {
      async function fetchData() {
        try {
          const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify({"email":global.email,"event":global.eventslug,"alias": global.alias})
          };
          const response = await fetch(process.env.REACT_APP_API_URL+process.env.REACT_APP_REGISTER, requestOptions).catch(error => {});
          if (response.ok) {
            const result = await response.json();
            //console.log(result);
            setToken(result.token);
            setEvent(result.event);
            global.token = result.token;
            global.eventslug = result.event;
            setError(null);
            setIsLoading(false);
            next();
          } else {
            setError("Hubo un error al obtener el objeto de registro");
            setIsLoading(false);
          }
        } catch (error) {
          setError("Error al realizar la solicitud de registro");
          setIsLoading(false);
        }
      }
      fetchData();
    }
  }, [isLoading]);

  //Conditional render
  if (isLoading) {
    return (
      <div className="App">
        <div className="App-loader-background"><div className="App-loader"></div></div>
        
      </div>
    );
  }

  if (error) {
    return (
      <div className="App">
        <h1>{error}</h1>
        <button onClick={reload}>Volver a intentarlo</button>
      </div>
    );
  }
  
}