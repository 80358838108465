import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import "../App.css";
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';
import buttonyellow from '../assets/images/button-yellow.svg';

export { Enviarencuesta };

function Enviarencuesta() {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [status, setStatus] = useState(null);
  
  const navigate = useNavigate();
  const  next = () => {
    navigate('/juegos/Juegos');
  }

  const reload = () => {
    setIsLoading(true);
  };

  const resetGameVars = () => {
    if(global.gameactive != null){
      global.gamename = null;
      global.gameid = null;
      global.gameactive = null;
      global.gametype = null;
      global.gamebg = null;
      global.surveydetails = null;
      global.surveyanswer = null;
    }
  }

  const  salir = () => {
    global.eventslug=null;
    global.token=null;
    global.exit=true;
    resetGameVars();
    navigate('/');
  }
  if(global.media_fondo_app==undefined){global.media_fondo_app = background;}

  useEffect(() => {
    if (isLoading) {
      async function fetchData() {
        try {
          let text = process.env.REACT_APP_API_URL+process.env.REACT_APP_SURVEY;
          let URI_SURVEY = text.replace("{id}", global.gameid);
          const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json','Authorization': 'Token '+global.token},
                body: JSON.stringify({
                  "alias": global.alias,
                  "game_encuesta_answer": global.surveyanswer,
                })
          };
          const response = await fetch(URI_SURVEY, requestOptions).catch(error => {});
          if (response.ok) {
            const result = await response.json();
            setStatus(result.status);
            setError(null);
            setIsLoading(false);
          } else {
            setError("Hubo un error al obtener el objeto");
            setIsLoading(false);
          }
        
        } catch (error) {
          setError("Error al realizar la solicitud");
          setIsLoading(false);
        }
      }
      fetchData();
    }
  }, [isLoading]);

  
  //Conditional renders
  if (isLoading) {
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
        <div className="App-loader-background"><div className="App-loader"></div></div>
        
      </div>
    );
  }

  if (error) {
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
        <h1>{error}</h1>
        <button onClick={reload}>Volver a intentarlo</button>
        <button onClick={salir}>Salir</button>
      </div>
    );
  }

  return (
    <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
          <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
          <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
        <div className="App-thanks-user">
          <h1 className="App-thanks-user-title">¡Gracias por<br></br>participar {global.alias}!</h1>
          <div className="App-thanks-user-text">En las pantallas<br></br>podrás ver los resultados<br></br>
        </div>
        <div className="contenedor-thanks" onClick = {next}>
          <img src={buttonyellow} className="buttonyellow" alt="buttonyellow" />
          <div className="centrado">Volver</div>
        </div>
        </div>
        {/* BANNER */}
        {global.footer_welcome_user!=null && <img src={global.footer_welcome_user} className="App-banner-footer" alt="bannerfooter" />}
      </div>

      <footer className="App-footer">
      <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div>
  );
}