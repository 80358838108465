import { useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';
import bottoneye from '../assets/images/button-eye.svg';
import buttonbluesmall from '../assets/images/button-blue-small.svg';

export { Bienvenidauser };

function Bienvenidauser() {

    const navigate = useNavigate();
    const  next = () => {
      navigate('/juegos/Juegos')
    }

    var eventname = "";
    if(global.eventname) {eventname=global.eventname;}
    else {eventname="Nombre del evento";}
    if(global.media_fondo_app==undefined){global.media_fondo_app = background;}

    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
        <div className="App-welcomeuser">
        <h1 className="App-welcomeuser-title">¡Bienvenido<br></br>{global.alias}!</h1>
        <img src={bottoneye} className="buttoneye" alt="bottoneye" />
        <div className="App-welcomeuser-text">Permanece atento/a a las pantallas<br></br>para participar en los juegos del<br></br>
          <h4>{eventname}</h4>
        </div>
        <div className="contenedor" onClick = {next}>
          <img src={buttonbluesmall} className="buttonblue" alt="buttonblue" />
          <div className="centrado">ACEPTAR</div>
        </div>
        </div>
        {/* BANNER */}
        {global.footer_welcome_user!=null && <img src={global.footer_welcome_user} className="App-banner-footer" alt="bannerfooter" />}
      </div>

      <footer className="App-footer">
      <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div> 
    );
}
