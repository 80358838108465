import { useNavigate, useLocation, redirect } from 'react-router-dom';
import { useCallback, useRef, useState, Fragment } from "react";
import '../App.css';
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';
import buttonyellow from '../assets/images/button-yellow.svg';
import { Camera } from "./camera";
import { Root } from "./styles";

export { Fotocam };

function Fotocam() {
    const navigate = useNavigate();
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [cardImage, setCardImage] = useState();
    var windowOverflow = false;
    const  volver = () => {
      setIsCameraOpen(false);
      setCardImage(undefined);
      navigate('/juegos/Juegos')
    }

    const  isWindowOverflow = () => {if(window.screen.height<=750){windowOverflow=true;}}
    if(global.media_fondo_app==undefined){global.media_fondo_app = background;}

    
 /*Si se han permitido los permisos de cámara y se obtuvieron los parametros del juego activo*/
  if(global.camara == true && global.gameactive == true && global.gametype == "fotocam"){
    isWindowOverflow();
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
        {!global.media_logo_app ? (<header className="App-header">
          <div><img src={logo} className="App-logo" alt="logo" /></div>
        </header>) : (<header className="App-header">
          <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
        </header>)}

        <div className="App-body-fotocam">
          <Fragment>
            <Root>
              <Camera
                onCapture={blob => setCardImage(blob)}
                onClear={() => setCardImage(undefined)}
              />
            </Root>
          </Fragment>
          <div className="contenedor" onClick = {volver}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonyellow" />
            <div className="centrado">Volver</div>
          </div>          
          {/*global.footer_in_game!=null && <img src={global.footer_in_game} className="App-banner-footer-in-game" alt="bannerfooter" />*/}
        </div>
        
        <footer className="App-footer">
          <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
        </footer> 
      </div>
      );
  }

  /*Si no han permitido los permisos de cámara*/
  if(!global.camara == true){
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
        <div id="games" className='game-container'>
          <p className="avisos">Para utilizar el juego, debe permitir el permiso de acceso a camara</p>
        </div>
        <div className="contenedor" onClick = {volver}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonblue" />
            <div className="centrado">Volver</div>
        </div>
      </div>

      <footer className="App-footer">
      <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div>
    );
  }

  /*Si no hay juegos activos*/
  if(global.gameactive != true){
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
        <div id="games" className='game-container'>
          <p>No hay juegos activos en este momento</p>
          <div className="contenedor" onClick = {volver}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonblue" />
            <div className="centrado">Volver</div>
          </div>
        </div>
      </div>

      <footer className="App-footer">
      <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div>
    );
  }
}
