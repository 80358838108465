import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../App.css';
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';
import _raw from './condicionesgenerales.txt';
import buttonblue from '../assets/images/button-blue.svg';
import buttonyellow from '../assets/images/button-yellow.svg';

export { Condicionesgenerales };

Cookies.set('cookies-necesarias', 'true', { expires: 7, secure: true, sameSite: 'none' });

function Condicionesgenerales() {
    const navigate = useNavigate();
    const  next = () => {
      navigate('/permisos')
    }

    const  configcookies = () => {
      navigate('/Cookiesconfig')
    }

    const [raw, setRaw] = useState("");
    if(global.media_cond_gener_app==undefined){fetch(_raw).then(r => r.text()).then(text => {setRaw(text)});}
    else{fetch(global.media_cond_gener_app).then(r => r.text()).then(text => {setRaw(text)});}
    
    global.conditions=true;
    if(global.media_fondo_app==undefined){global.media_fondo_app = background;}

    return (
    <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
        <div className="App-conditions">
          <h3 className="App-conditions-title">CONDICIONES GENERALES DE USO PARA APLICACIONES MÓVILES</h3>
          <p className="App-conditions-text">
            {/*raw*/}
            <div dangerouslySetInnerHTML={{__html: raw}} />
          </p>
          <div className="contenedor" onClick = {next}>
            <img src={buttonblue} className="buttonblue" alt="buttonblue" />
            <div className="centrado">Aceptar Cookies recomendadas</div>
          </div>
          <div className="contenedor" onClick = {configcookies}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonyellow" />
            <div className="centrado">Administrar Cookies</div>
          </div>

        </div>
      </div>
      
      <footer className="App-footer">
        <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div> 
    );
}
