import React, { useState, useRef, Fragment } from "react";
import { useNavigate, useLocation, redirect } from 'react-router-dom';
import Measure from "react-measure";
import { useUserMedia } from "../hooks/use-user-media";
import { useCardRatio } from "../hooks/use-card-ratio";
import { useOffsets } from "../hooks/use-offsets";
import camara from '../../assets/images/button-fotocam.svg';
import buttonblue from '../../assets/images/button-blue.svg';
import buttonyellow from '../../assets/images/button-yellow.svg';
import {
  Video,
  Canvas,
  Wrapper,
  Container,
  Flash,
  Overlay,
  Button,
  Mascara
} from "./styles";

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: "user" }
};
const QUALITY = 0.7;

export function Camera({ onCapture, onClear }) {
  const navigate = useNavigate();
  const canvasRef = useRef();
  const canvasRefAux = useRef();
  const videoRef = useRef();
  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);
  const mediaStream = useUserMedia(CAPTURE_OPTIONS);

  const [aspectRatio, calculateRatio] = useCardRatio(1);
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height
  );

  //Cargar la máscara
  var downloadedImg = new Image();
  var imageReceived = new Image();
  downloadedImg.crossOrigin = "anonymous";
  downloadedImg.addEventListener("load", imageReceived, false);
  downloadedImg.src = global.gamebg;

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  function handleResize(contentRect) {
    setContainer({
      width:  350, /*contentRect.bounds.width*/
      height: 450,/*Math.round(contentRect.bounds.width / aspectRatio)*/
    });
  }

  function handleCanPlay() {
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    videoRef.current.play();
  }

  //Capturar
  function handleCapture() {
    const context = canvasRef.current.getContext("2d");
    const contextAux = canvasRefAux.current.getContext("2d");
    context.drawImage(
      videoRef.current,
      offsets.x,
      offsets.y,
      container.width,
      container.height,
      0,
      0,
      container.width,
      container.height
    );
	  context.save();
	  context.translate(canvasRef.current.width, 0);
	  context.scale(-1, 1);
	  context.drawImage(canvasRef.current, 0, 0, container.width, container.height);
    context.restore();
    if(global.gamebg){context.drawImage(downloadedImg,0,0,container.width,container.height);}
    contextAux.drawImage(
      videoRef.current,
      offsets.x,
      offsets.y,
      container.width,
      container.height,
      0,
      0,
      container.width,
      container.height
    );
    if(global.gamebg){contextAux.drawImage(downloadedImg,0,0,container.width,container.height);}
    canvasRef.current.toBlob(blob => onCapture(blob), "image/jpeg", 1);
    let image = canvasRefAux.current.toDataURL("image/jpeg", QUALITY);
    let base64 = image.split(',');
    global.fotobase64 = base64[1];
    //console.log(base64[1]);
    setIsCanvasEmpty(false);
    setIsVideoPlaying(false);
    setIsFlashing(true);
  }

  //Repetir
  function handleClear() {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    setIsVideoPlaying(true);
    setIsFlashing(false);
    onClear();
    document.getElementById("container").hidden=false;
    document.getElementById("comentarios").hidden=true;
  }

  function cleanStr(){
    let words = ["<script>","</script>","text/javascript","alert","<","</",">","(",")","/*","*/","<a","</a>","href","<img","src","<iframe","<frameset","<input","<?","<xml","<style","</style>","<video","<object","type","onload","onclick","="];
    let strOut = global.fotocomentario;
    for(let i=0 ; i < strOut.length ; i++){
      while(strOut.includes(words[i])){
        words.forEach(element => {
          strOut = strOut.replace(element, "");
        });
      }
    }
    global.fotocomentario = strOut;
  }

  function enviar () {
    if(global.fotobase64 != null) {
      if (!global.fotocomentario){
        comentarFoto();
      } else {
        if(document.getElementById("comentariofoto").value!=""){global.fotocomentario = document.getElementById("comentariofoto").value;}
        else {global.fotocomentario = ""}
        cleanStr();
        navigate('/juegos/Enviarfoto');
      }
    } else {
      alert("Primero debes tomar la foto");
    }
  }

  function comentarFoto () {
    global.fotocomentario="empty";
    document.getElementById("container").hidden=true;
    document.getElementById("repetir").hidden=true;
    document.getElementById("comentarios").hidden=false;
  }

  if (!mediaStream) {return null;}

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <Wrapper>
          <Container
            id="container"
            ref={measureRef}
            maxheight={videoRef.current && videoRef.current.videoHeight}
            maxwidth={videoRef.current && videoRef.current.videoWidth}
            style={{
              height: `${container.height}px`
            }}
          >
            <Video
              id="video"
              ref={videoRef}
              hidden={!isVideoPlaying}
              onCanPlay={handleCanPlay}
              autoPlay
              playsInline
              muted
              style={{
                top: `-${offsets.y}px`,
                left: `-${offsets.x}px`
              }}
            />
            <Canvas
              id="canvas"
              ref={canvasRef}
              hidden={isCanvasEmpty}
              width={container.width}
              height={container.height}
            />
            <Canvas
              id="canvasAux"
              ref={canvasRefAux}
              hidden={true}
              width={container.width}
              height={container.height}
            />
            {isVideoPlaying && global.gamebg && <Mascara src={downloadedImg.src} />}
            {isFlashing && (
            <Flash
              flash="true"
              onAnimationEnd={() => setIsFlashing(false)}
            />)}
          </Container>
          <div id="comentarios" className="comentarios" hidden >
            <label className="comentarios-label">Comenta tu foto:</label>
            <div><textarea type="text" id="comentariofoto" className="comentariofoto" required maxLength="50"/></div>
          </div>

          {isCanvasEmpty ? (
            <div id="boton-camara" onClick={handleCapture}><img src={camara} className="boton-camara"/></div>
          ) : (
          <div id="repetir" className="boton-dinamico" onClick={handleClear}>
            <img src={buttonblue} className="buttonblue-small" alt="repetir" />
            <div className="centrado">Repetir</div>
          </div>
          )}

          {!isCanvasEmpty && (
          <div id="enviar" className="boton-dinamico" onClick={enviar}>
            <img src={buttonyellow} className="buttonyellow-small" alt="enviar" />
            <div className="centrado">Enviar</div>
          </div>
          )}
        </Wrapper>
      )}
    </Measure>
  );
}