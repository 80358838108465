import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../App.css';
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';
import buttonblue from '../assets/images/button-blue.svg';
import buttonyellow from '../assets/images/button-yellow.svg';

export { Registro };

function Registro() {

    const navigate = useNavigate();
    const  next = () => {
      navigate('/account/registrar');
    }

    const  cancel = () => {
      navigate('../../permisos/Permisos')
    }

    const Registrarse = () => {
      if(document.getElementById("alias").value!="" 
        && document.getElementById("email").value!=""  
        && document.getElementById("emailconfirmed").value!="" && (document.getElementById("email").value == document.getElementById("emailconfirmed").value)) { 
          global.alias = document.getElementById("alias").value;
          global.email = document.getElementById("email").value;
          next();
      } else if(document.getElementById("email").value != document.getElementById("emailconfirmed").value){
        alert("El email debe ser el mismo en ambos casos");
      }
      else {
        alert("Por favor rellene todos los campos");
      }
      
    }

    const dispatch = useDispatch();
    if(global.media_fondo_app==undefined){global.media_fondo_app = background;}

    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
      <div className="App-registro">
        <div className="App-registro-text">
            <form >
                    <div>
                        <input id="alias" type="text" className="form-registro" required maxLength="14"
                        placeholder="Introduce un alias"/>
                    </div>
                    <div>
                        <input id="email" type="email" className="form-registro"
                         placeholder="Introduce tu email"/>
                    </div>
                    <div>
                        <input id="emailconfirmed" type="email" className="form-registro"
                         placeholder="Confirma tu email"/>
                    </div>
            </form>
        </div>

        <div className="contenedor" onClick = {Registrarse}>
          <img src={buttonblue} className="buttonblue" alt="buttonblue" />
          <div className="centrado">ACEPTAR</div>
        </div>

        <div className="contenedor" onClick = {cancel}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonyellow" />
            <div className="centrado">VOLVER</div>
        </div>

      </div>
      </div>

      <footer className="App-footer">
        <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div> 
    )
}
