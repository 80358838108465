import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { history } from '_helpers';
import { Nav, Alert, PrivateRoute } from '_components';
import { Bienvenida } from 'bienvenida';
import { Bienvenidauser } from 'bienvenidauser';
import { Condicionesgenerales } from 'condicionesgenerales';
import { Cookiesconfig } from 'cookiesconfig';
import { Permisos } from 'permisos';
import { Finalizado } from 'finalizado';
import { AccountLayout } from 'account';
import { JuegosLayout } from 'juegos';
import "./App.css";
import logo from './assets/images/ufan-logo.svg';
import background from './assets/images/background.jpg';
//import { array } from 'yup';

export { App };
//Función para la captura del slug del evento
function getEvent() {
    if(!global.eventslug) {
        const urlactual = window.location.href;
        const eventurl = urlactual.split('/');
        global.capturedevent = eventurl[3];
    }
}

function App()  {
    /* Inicia el objeto de historial personalizado para permitir
    la navegación en cualquier lugar de la aplicación react app 
    (componentes internos o externos)*/
    history.navigate = useNavigate();
    history.location = useLocation();
    
    //Constantes para la carga
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [token, setToken] = useState(null);
    const [eventslug, setEventSlug] = useState(null);
    const [eventname, setEventName] = useState(null);
    const [eventisactive, setEventIsActive] = useState(null);
    //Declaramos useNavigate
    const navigate = useNavigate();

    //Capturar slug del evento
    getEvent();

    //Carga asincrona del evento
    useEffect(() => {
        
        if (isLoading) {
          async function fetchData() {
            try {
              const requestOptions = {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'}
              };
              const response = await fetch(process.env.REACT_APP_API_URL+process.env.REACT_APP_EVENT+global.capturedevent, requestOptions);
              if (response.ok) {
                const result = await response.json();
                setEventName(result.name);
                setEventSlug(result.slug);
                setEventIsActive(result.is_active);
                global.eventname = result.name;
                global.eventslug = result.slug;
                global.events_is_active = result.is_active;
                global.exit=false;
                
                //Sponsor media
                if(result.sponsors.length > 0){
                    global.footer_welcome_user = null;
                    global.footer_games = null;
                    global.footer_in_game = null;
                    global.footer_thankyou = null;
                    for (var i = 0; i < result.sponsors.length; i++) {
                        for (var j = 0; j < result.sponsors[i].medias.length; j++) {
                            switch(result.sponsors[i].medias[j].tag) {
                                case "media_logo_app":
                                    if(result.sponsors[i].medias[j].active){global.media_logo_app = result.sponsors[i].medias[j].file};
                                break;
                                case "media_fondo_app":
                                    if(result.sponsors[i].medias[j].active){global.media_fondo_app = result.sponsors[i].medias[j].file;};
                                break;
                                case "media_cond_gener_app":
                                    if(result.sponsors[i].medias[j].active){global.media_cond_gener_app = result.sponsors[i].medias[j].file;};
                                break;
                                case "media_conf_permi_app":
                                    if(result.sponsors[i].medias[j].active){global.media_conf_permi_app = result.sponsors[i].medias[j].file;};
                                break;                                
                                case "footer_welcome_user":
                                    if(result.sponsors[i].medias[j].active){global.footer_welcome_user = result.sponsors[i].medias[j].file};
                                break;
    
                                case "footer_games":
                                    if(result.sponsors[i].medias[j].active){global.footer_games = result.sponsors[i].medias[j].file};
                                break;

                                case "footer_in_game":
                                    if(result.sponsors[i].medias[j].active){global.footer_in_game = result.sponsors[i].medias[j].file};
                                break;
    
                                case "footer_thankyou":
                                    if(result.sponsors[i].medias[j].active){global.footer_thankyou = result.sponsors[i].medias[j].file};
                                break;
                            }
                        }
                    }
                }

                setError(null);
                setIsLoading(false);
              } else {
                setError("Hubo un error al obtener el objeto de registro");
                setIsLoading(false);
              }
            } catch (error) {
              setError("Error al realizar la solicitud de registro");
              setIsLoading(false);
            }
          }
          fetchData();
        }
      }, [isLoading]);

    if(global.media_fondo_app==undefined){global.media_fondo_app = background;}

    /** Carga App **/
    if (isLoading) {
        return (
            <div className="App">
                <div className="App-loader-background"><div className="App-loader"></div></div>
            </div>
        );
    }
    /** Evento no encontrado **/
    if (error) {
        return (
            <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
                {!global.media_logo_app ? (<header className="App-header">
                <div><img src={logo} className="App-logo" alt="logo" /></div>
                </header>) : (<header className="App-header">
                <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
                </header>)}

                <div className="App-body">
                    <div className="App-welcome">
                        <h1 className="App-welcome-title">¡Gracias por <br></br>usar UFAN!</h1>
                        <p className="App-welcome-text">Os esperamos<br></br>en el próximo evento</p>
                    </div>
                </div>
                <footer className="App-footer">
                    <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
                </footer>
            </div> 
        );
    }
    
    /** Evento encontrado **/
    if(global.eventslug && global.events_is_active==true && global.exit==false) {
        return (
            <div>
                <Nav />
                <Alert />
                <div>
                    <Routes>
                        {/* private Routes*/}
                        <Route element={<PrivateRoute />}>
                            <Route path="/" element={<Bienvenida />} />
                        </Route>
                        {/* public Routes*/}
                        <Route path="bienvenida/*" element={<Bienvenida />} />
                        <Route path="bienvenidauser/*" element={<Bienvenidauser />} />
                        <Route path="condicionesgenerales/*" element={<Condicionesgenerales />} />
                        <Route path="cookiesconfig/*" element={<Cookiesconfig />} />
                        <Route path="permisos/*" element={<Permisos />} />
                        <Route path="account/*" element={<AccountLayout />} />
                        <Route path="juegos/*" element={<JuegosLayout />} />
                        <Route path="finalizado/*" element={<Finalizado />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
            </div>
        );
    }

    return (
        <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
                {!global.media_logo_app ? (<header className="App-header">
                <div><img src={logo} className="App-logo" alt="logo" /></div>
                </header>) : (<header className="App-header">
                <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
                </header>)}

                <div className="App-body">
                    <div className="App-welcome">
                    <h1 className="App-welcome-title">¡Gracias por <br></br>usar UFAN!</h1>
                    <p className="App-welcome-text">Os esperamos<br></br>en el próximo evento</p>
                    </div>
                </div>

                <footer className="App-footer">
                    <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
                </footer>
            </div> 
    );
}