import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import '../App.css';
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';
import buttonblue from '../assets/images/button-blue.svg';
import buttonyellow from '../assets/images/button-yellow.svg';

function validar() { 
  let checkbox1 = document.getElementById("cookies-necesarias");
  let checkbox2 = document.getElementById("cookies-analisis-rendimiento");
  let checkbox3 = document.getElementById("cookies-segmentacion-seguridad");

  if (checkbox2.checked == false) {
    checkbox2.checked = false; 
    Cookies.remove("cookies-analisis-rendimiento");} 
  else {
    checkbox2.checked = true; 
    Cookies.set('cookies-analisis-rendimiento', 'true', { expires: 7 });
  }
  if (checkbox3.checked == false) {
    checkbox3.checked = false; 
    Cookies.remove("cookies-segmentacion-seguridad");
  } else {
    checkbox3.checked = true; 
    Cookies.set('cookies-segmentacion-seguridad', 'true', { expires: 7 });
  }
}

function GetCookie (elemento) {
  return Cookies.get(elemento);
};

function RemoveCookie (elemento) {
  return Cookies.remove(elemento);
};

export { Cookiesconfig };

function Cookiesconfig() {
    const navigate = useNavigate();
    const  next = () => {
      navigate('/permisos')
    }
    
    const  configcookies = () => {
      navigate('/Condicioneslegales')
    }
    if(global.media_fondo_app==undefined){global.media_fondo_app = background;}
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
      <div className="App-cookies-settings">
      < h3 className="App-cookies-title">COFIGURACIÓN DE LAS COOKIES</h3>
        <div className="App-cookies-text">
        <div className="form-check form-switch">
          <input className="form-check-input" type="checkbox" role="switch" id="cookies-necesarias" onClick={validar} checked disabled/>
          <label className="form-check-label-1"><b>Cookies estrictamente necesarias</b></label>
          <p>Las cookies necesarias ayudan a hacer una página web utilizable activando funciones básicas como la navegación en la página y el acceso a áreas seguras de la página web.<br/>La página web no puede funcionar adecuadamente sin estas cookies.</p>
        </div>

        <div className="form-check form-switch">
          <input className="form-check-input" type="checkbox" role="switch" id="cookies-analisis-rendimiento" onClick={validar} />
          <label className="form-check-label-2"><b>Cookies de análisis y rendimiento</b></label>
          <p>Estas cookies nos permiten contar las visitas y fuentes de circulación para poder medir y mejorar el desempeño de nuestro sitio</p>
        </div>

        <div className="form-check form-switch">
          <input className="form-check-input" type="checkbox" role="switch" id="cookies-segmentacion-seguridad" onClick={validar}/>
          <label className="form-check-label-3"><b>Cookies de segmentación y seguridad</b></label>
          <p>Las cookies de segmentación se utilizan para realizar campañas publicitarias. Según los datos de navegación y el comportamiento del usuario</p>
        </div>
        </div>

        <div className="contenedor" onClick = {next}>
          <img src={buttonblue} className="buttonblue" alt="buttonblue" />
          <div className="centrado">CONTINUAR</div>
        </div>
        <div className="contenedor" onClick = {configcookies}>
          <img src={buttonyellow} className="buttonyellow" alt="buttonyellow" />
          <div className="centrado">VOLVER</div>
        </div>

      </div>
      </div>

      <footer className="App-footer">
        <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div> 
    );
}
