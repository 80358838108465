import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Registro, Registrar } from './';

export { AccountLayout };

function AccountLayout() {
    const auth = useSelector(x => x.auth.value);

    // redirect to home if already logged in
    /*if (auth) {
        return <Navigate to="/" />;
    }*/

    return ( //<Route path="login" element={<Login />} />      <Route path="register" element={<Register />} />
        <div>
            <div>
                <div>
                    <Routes>
                        <Route path="registro" element={<Registro />} />
                        <Route path="registrar" element={<Registrar />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}
