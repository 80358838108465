import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from "react";
import '../App.css';
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';
import buttonblue from '../assets/images/button-blue.svg';
import buttonyellow from '../assets/images/button-yellow.svg';
import buttonquizgreen from '../assets/images/survey/option-green-a.svg';
import buttonquizvio from '../assets/images/survey/option-vio-b.svg';
import buttonquizred from '../assets/images/survey/option-red-c.svg';
import buttonquizblue from '../assets/images/survey/option-blue-d.svg';
import buttonquizgreen_a from '../assets/images/survey/button-green-a.svg';
import buttonquizvio_b from '../assets/images/survey/button-vio-b.svg';
import buttonquizred_c from '../assets/images/survey/button-red-c.svg';
import buttonquizblue_d from '../assets/images/survey/button-blue-d.svg';
export { Encuesta };

function Encuesta() {
    const navigate = useNavigate();
    const [counter, setCounter] = useState(60);
    //const [favImages, setFavImages] = useState([]);
    global.surveyparticipate=true;
    global.previousgameid=global.gameid;
    let answer_0 = null;
    let answer_1 = null;
    let answer_2 = null;
    let answer_3 = null;

    const enviar = () => {
      if(global.surveyanswer != null) {
        navigate('/juegos/Enviarencuesta');
      } else {
        alert("Primero debes seleccionar una respuesta");
      }
    };

    const  volver = () => {
      navigate('/juegos/Juegos')
    }
    console.log();
    const loaddata = () => {
      if(global.surveydetails.question!=undefined){
        switch (global.surveydetails.answers.length) {
          case 1:
            if(global.surveydetails.answers[0].answer){answer_0 = global.surveydetails.answers[0].answer;}
          break;
          case 2:
            if(global.surveydetails.answers[0].answer){answer_0 = global.surveydetails.answers[0].answer;}  
            if(global.surveydetails.answers[1].answer){answer_1 = global.surveydetails.answers[1].answer;}
          break;
            
          case 3:
            if(global.surveydetails.answers[0].answer){answer_0 = global.surveydetails.answers[0].answer;}  
            if(global.surveydetails.answers[1].answer){answer_1 = global.surveydetails.answers[1].answer;}
            if(global.surveydetails.answers[2].answer){answer_2 = global.surveydetails.answers[2].answer;}
          break;

          case 4:
            if(global.surveydetails.answers[0].answer){answer_0 = global.surveydetails.answers[0].answer;}  
            if(global.surveydetails.answers[1].answer){answer_1 = global.surveydetails.answers[1].answer;}
            if(global.surveydetails.answers[2].answer){answer_2 = global.surveydetails.answers[2].answer;}
            if(global.surveydetails.answers[3].answer){answer_3 = global.surveydetails.answers[3].answer;}
          break;
        }
      }
    }
    
    //Convert to Switch
    const answer_a = () => {
      global.surveyanswer = global.surveydetails.answers[0].id;
      enviar();
    }
    const answer_b = () => {
      global.surveyanswer = global.surveydetails.answers[1].id;
      enviar();
    }
    const answer_c = () => {
      global.surveyanswer = global.surveydetails.answers[2].id;
      enviar();
    }
    const answer_d = () => {
      global.surveyanswer = global.surveydetails.answers[3].id;;
      enviar();
    }   

    useEffect(() => {
      const interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
      return () => clearInterval(interval);
    }, []);

  loaddata();
  if(global.media_fondo_app==undefined){global.media_fondo_app = background;}

  /*Si se obtuvieron los parametros del juego activo*/
  if(global.gameactive == true && global.gametype == "encuesta" && global.surveydetails.question!=undefined && counter>0){
    if (counter == 3){document.getElementById("counter").classList.toggle('counter-limit');}
    global.counter = counter;
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
        {!global.media_logo_app ? (<header className="App-header">
          <div><img src={logo} className="App-logo" alt="logo" /></div>
        </header>) : (<header className="App-header">
          <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
        </header>)}
  
        <div className="App-body">
          <div className="game-quiz">
            <h3 className="game-quiz-title">{global.surveydetails.question}</h3>
              <h1 id="counter" className="counter">{/*counter*/}</h1>
              <div className="game-quiz-text">
                <div id="answers" name="answers" className="game-quiz-answer">
                  {answer_0 && <img src={buttonquizgreen} className="option-quiz" alt="" />}<div className="game-quiz-answer">{answer_0}</div>
                  {answer_1 && <img src={buttonquizvio} className="option-quiz" alt="" />}<div className="game-quiz-answer">{answer_1}</div>
                  {answer_2 && <img src={buttonquizred} className="option-quiz" alt="" />}<div className="game-quiz-answer">{answer_2}</div>
                  {answer_3 && <img src={buttonquizblue} className="option-quiz" alt="" />}<div className="game-quiz-answer">{answer_3}</div>
                </div>

                <div>
                  {answer_0 && <img src={buttonquizgreen_a} className="select-option-quiz" alt="" onClick={answer_a}/>}
                  {answer_1 && <img src={buttonquizvio_b} className="select-option-quiz" alt="" onClick={answer_b}/>}
                  {answer_2 && <img src={buttonquizred_c} className="select-option-quiz" alt="" onClick={answer_c}/>}
                  {answer_3 && <img src={buttonquizblue_d} className="select-option-quiz" alt="" onClick={answer_d}/>}
                </div>
              </div>
          </div>
          <div className="contenedor" onClick = {volver}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonyellow" />
              <div className="centrado">Volver</div>
          </div>
          {global.footer_in_game!=null && <img src={global.footer_in_game} className="App-banner-footer-in-game" alt="bannerfooter" />}
        </div>
  
        <footer className="App-footer">
          <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
        </footer>
      </div>
      );
  } 
  
  if (counter == 0 && global.surveydetails.question!=undefined){
    volver();
  }
  
  /*Si no hay juegos activos*/
  if(global.gameactive != true || global.surveydetails.question == undefined){
    return (
    <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
          <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
          <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
        <div id="games" className='game-container'>
          <p>No hay juegos activos en este momento</p>
          <div className="contenedor" onClick = {volver}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonblue" />
            <div className="centrado">Volver</div>
          </div>
        </div>
      </div>

      <footer className="App-footer">
      <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div>
    );
  }
}
