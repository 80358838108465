import { useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';

export { Finalizado };

function Finalizado() {

    const navigate = useNavigate();
    const  next = () => {
      navigate('/condicioneslegales')
    }
    if(global.media_fondo_app==undefined){global.media_fondo_app = background;}

    return (
    <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
          <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
          <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
        <div className="App-welcome" onClick = {next}>
        <h1 className="App-welcome-title">¡Gracias por <br></br>usar UFAN!</h1>
        <p className="App-welcome-text">Os esperamos<br></br>en el próximo evento</p>
        </div>
      </div>

      <footer className="App-footer">
      <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div>
    );
}
