import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from "react";
import '../App.css';
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';
import buttonblue from '../assets/images/button-blue.svg';
import buttonyellow from '../assets/images/button-yellow.svg';
import buttonquizgreen from '../assets/images/quiz/option-green-a.svg';
import buttonquizvio from '../assets/images/quiz/option-vio-b.svg';
import buttonquizred from '../assets/images/quiz/option-red-c.svg';
import buttonquizblue from '../assets/images/quiz/option-blue-d.svg';
import buttonquizgreen_a from '../assets/images/quiz/button-green-a.svg';
import buttonquizvio_b from '../assets/images/quiz/button-vio-b.svg';
import buttonquizred_c from '../assets/images/quiz/button-red-c.svg';
import buttonquizblue_d from '../assets/images/quiz/button-blue-d.svg';
export { Quiz };

function Quiz() {
    const navigate = useNavigate();
    const [counter, setCounter] = useState(10);
    const [favImages, setFavImages] = useState([]);
    if(global.index!=null){index = global.index} else {var index = 0}
    global.quizparticipate=true;
    global.previousgameid=global.gameid;
    let answer_0 = null;
    let answer_1 = null;
    let answer_2 = null;
    let answer_3 = null;

    const enviar = () => {
      if(global.quizanswer != null) {
        navigate('/juegos/Enviarquiz');
      } else {
        alert("Primero debes seleccionar una respuesta");
      }
    };

    const  volver = () => {
      navigate('/juegos/Juegos')
    }

    const loaddata = () => {
      if(global.quizdetails.questions[index]!=undefined/*global.quizdetails!=null*/){
        if(global.index == global.quizdetails.questions.length){volver()}
        switch (global.quizdetails.questions[index].answers.length) {
          case 1:
            if(global.quizdetails.questions[index].answers[0].answer!=undefined){answer_0 = global.quizdetails.questions[index].answers[0].answer;}
          break;
          case 2:
            if(global.quizdetails.questions[index].answers[0].answer!=undefined){answer_0 = global.quizdetails.questions[index].answers[0].answer;}  
            if(global.quizdetails.questions[index].answers[1].answer!=undefined){answer_1 = global.quizdetails.questions[index].answers[1].answer;}
          break;
            
          case 3:
            if(global.quizdetails.questions[index].answers[0].answer!=undefined){answer_0 = global.quizdetails.questions[index].answers[0].answer;}  
            if(global.quizdetails.questions[index].answers[1].answer!=undefined){answer_1 = global.quizdetails.questions[index].answers[1].answer;}
            if(global.quizdetails.questions[index].answers[2].answer!=undefined){answer_2 = global.quizdetails.questions[index].answers[2].answer;}
          break;

          case 4:
            if(global.quizdetails.questions[index].answers[0].answer!=undefined){answer_0 = global.quizdetails.questions[index].answers[0].answer;}  
            if(global.quizdetails.questions[index].answers[1].answer!=undefined){answer_1 = global.quizdetails.questions[index].answers[1].answer;}
            if(global.quizdetails.questions[index].answers[2].answer!=undefined){answer_2 = global.quizdetails.questions[index].answers[2].answer;}
            if(global.quizdetails.questions[index].answers[3].answer!=undefined){answer_3 = global.quizdetails.questions[index].answers[3].answer;}
          break;
        }
      }
    }
  
    //Convert to Switch
    const answer_a = () => {
      global.quizanswer = global.quizdetails.questions[index].answers[0].answer;
      enviar();
    }
    const answer_b = () => {
      global.quizanswer = global.quizdetails.questions[index].answers[1].answer;
      enviar();
    }
    const answer_c = () => {
      global.quizanswer = global.quizdetails.questions[index].answers[2].answer;
      enviar();
    }
    const answer_d = () => {
      global.quizanswer = global.quizdetails.questions[index].answers[3].answer;
      enviar();
    }    

    useEffect(() => {
      const interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
      return () => clearInterval(interval);
    }, []);
  
  loaddata();
  if(global.media_fondo_app==undefined){global.media_fondo_app = background;}
  
  /*Si se obtuvieron los parametros del juego activo*/
  if(global.gameactive == true && global.gametype == "quiz" && global.quizdetails.questions[index]!=undefined && counter>0){
    if (counter == 3){document.getElementById("counter").classList.toggle('counter-limit');}
    global.counter = counter;
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
        {!global.media_logo_app ? (<header className="App-header">
          <div><img src={logo} className="App-logo" alt="logo" /></div>
        </header>) : (<header className="App-header">
          <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
        </header>)}

        <div className="App-body">
          <div className="game-quiz">
            <h3 className="game-quiz-title">{global.quizdetails.questions[index].question}</h3>
              <h1 id="counter" className="counter">{counter}</h1>
              <div className="game-quiz-text">
                <div id="answers" name="answers" className="game-quiz-answer">
                  {answer_0 && <img src={buttonquizgreen} className="option-quiz" alt="" />}<div className="game-quiz-answer">{answer_0}</div>
                  {answer_1 && <img src={buttonquizvio} className="option-quiz" alt="" />}<div className="game-quiz-answer">{answer_1}</div>
                  {answer_2 && <img src={buttonquizred} className="option-quiz" alt="" />}<div className="game-quiz-answer">{answer_2}</div>
                  {answer_3 && <img src={buttonquizblue} className="option-quiz" alt="" />}<div className="game-quiz-answer">{answer_3}</div>
                </div>

                <div>
                  {answer_0 && <img src={buttonquizgreen_a} className="select-option-quiz" alt="" onClick={answer_a}/>}
                  {answer_1 && <img src={buttonquizvio_b} className="select-option-quiz" alt="" onClick={answer_b}/>}
                  {answer_2 && <img src={buttonquizred_c} className="select-option-quiz" alt="" onClick={answer_c}/>}
                  {answer_3 && <img src={buttonquizblue_d} className="select-option-quiz" alt="" onClick={answer_d}/>}
                </div>
              </div>
          </div>
          <div className="contenedor" onClick = {volver}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonyellow" />
              <div className="centrado">Volver</div>
          </div>
          {global.footer_in_game!=null && <img src={global.footer_in_game} className="App-banner-footer-in-game" alt="bannerfooter" />}
        </div>
  
        <footer className="App-footer">
          <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
        </footer>
      </div>
      );
  } 
  if (counter == 0 && global.quizdetails.questions[index]!=undefined){
    global.quizanswer = "notanswered";
    enviar();
  }

  /*Si no hay preguntas configuradas*/
  if(global.quizdetails.questions[index]==undefined){
    return (
    <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
        <div id="games" className='game-container'>
          <p>No hay preguntas activas en este momento</p>
          <div className="contenedor" onClick = {volver}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonblue" />
            <div className="centrado">Volver</div>
          </div>
        </div>
      </div>

      <footer className="App-footer">
      <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div>
    );
  }
  /*Si no hay juegos activos*/
  if(global.gameactive != true){
    return (
    <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
        <div id="games" className='game-container'>
          <p>No hay juegos activos en este momento</p>
          <div className="contenedor" onClick = {volver}>
            <img src={buttonyellow} className="buttonyellow" alt="buttonblue" />
            <div className="centrado">Volver</div>
          </div>
        </div>
      </div>

      <footer className="App-footer">
      <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div>
    );
  }
}
