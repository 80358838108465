import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../App.css';
import logo from '../assets/images/ufan-logo.svg';
import background from '../assets/images/background.jpg';
import buttonblue from '../assets/images/button-blue.svg';
import _raw from './autorizaciones.txt';

export { Permisos };

function Permisos() {
    const navigate = useNavigate();
    const  next = () => {
      if(document.getElementById("autorizaciones").checked==false){alert("Debe marcar las autorizaciones para utilizar UFAN");}
      else {navigate('/account/Registro')}
    }
    const [raw, setRaw] = useState("");
    if(global.media_conf_permi_app==undefined){fetch(_raw).then(r => r.text()).then(text => {setRaw(text)});}
    else{fetch(global.media_conf_permi_app).then(r => r.text()).then(text => {setRaw(text)});}

    const validar = (elemento) => { 
      //var checkbox1 = document.getElementById("ubicacion");
      var checkbox2 = document.getElementById("camara");
      var checkbox3 = document.getElementById("sensores");
      //var checkbox4 = document.getElementById("notificaciones");
      var checkbox5 = document.getElementById("autorizaciones");
    
      if (checkbox2.checked == false) {
        checkbox2.checked = false; 
        Cookies.remove("camara");
        global.camara=false;
      } else {
        checkbox2.checked = true; 
        Cookies.set('camara', 'true', { expires: 7, secure: true, sameSite: 'none' });
        global.camara=true;
      }

      if (checkbox3.checked == false) {
        checkbox3.checked = false; 
        Cookies.remove("sensores");
        global.sensors=false;
      } else {
        checkbox3.checked = true; 
        Cookies.set('sensores', 'true', { expires: 7, secure: true, sameSite: 'none' });
        global.sensors=true;
      }
    
      //Autorizaciones
      if (checkbox5.checked == false) {
        checkbox5.checked = false; 
        Cookies.remove("Autorizaciones");
        global.autorizaciones=false;
      } else {
        checkbox5.checked = true; 
        Cookies.set('Autorizaciones', 'true', { expires: 7, secure: true, sameSite: 'none' });
        global.autorizaciones=true;
      }
    }    
    if(global.media_fondo_app==undefined){global.media_fondo_app = background;}
    //onLoad={toHTML()}
    return (
      <div className="App" style={{backgroundImage: `url("${global.media_fondo_app}")`}}>
      {!global.media_logo_app ? (<header className="App-header">
        <div><img src={logo} className="App-logo" alt="logo" /></div>
      </header>) : (<header className="App-header">
        <div><img src={global.media_logo_app} className="App-logo" alt="logo" /></div>
      </header>)}

      <div className="App-body">
      <div className="App-cookies-settings">
        <h3 className="App-cookies-title">COFIGURACIÓN DE PERMISOS</h3>
        <div className="App-cookies-text">
          <div className="form-check form-switch">
            <input className="form-permisos-input" type="checkbox" role="switch" id="camara" onClick={validar} />
            <label className="form-permisos-label">Acceso a cámara</label>
          </div>
          <div className="form-check form-switch">
            <input className="form-permisos-input" type="checkbox" role="switch" id="sensores" onClick={validar} />
            <label className="form-permisos-label">Acceso a sensores</label>
          </div>
          <div className="form-check form-switch">
            <input className="form-permisos-input" type="checkbox" role="switch" id="autorizaciones" onClick={validar} />
            <label className="form-permisos-label">Autorizaciones UFAN!</label>
            <p className="form-permisos-text">
              <div dangerouslySetInnerHTML={{__html: raw}} />
            </p>
          </div>
        </div>
        <div className="contenedor" onClick = {next}>
          <img src={buttonblue} className="buttonblue" alt="buttonblue" />
          <div className="centrado">ACEPTAR</div>
        </div>
      </div>
      </div>

      <footer className="App-footer">
        <p>{process.env.REACT_APP_TEXTFOOTER} <b>{process.env.REACT_APP_TEXTFOOTERBOLD}</b></p>
      </footer>
    </div> 
    );
}
